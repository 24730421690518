<template>
  <v-container style="margin-top: 100px">
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <v-card
          class="pa-2"
          elevation="0"
          v-for="(item, index) in detail"
          :key="index"
        >
          <v-card-title
            v-text="item.title"
            dir="auto"
            style="
              color: #232a31;
              font-size: 3.08em;
              font-stretch: normal;
              font-style: normal;
              font-weight: 700;
              letter-spacing: normal;
              line-height: 1.2;
            "
          ></v-card-title>
          <ul style="list-style: none">
            <li class="article_date">
              <time v-text="item.date"></time>
            </li>
          </ul>

          <v-img
            :src="item.image"
            v-on:error="item.image = MercanewsLogo"
            height="400px"
            style="margin: 10px"
            contain
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <v-card
          class="pa-2"
          elevation="0"
          v-for="(item, index) in detail"
          :key="index"
        >
          <v-card-subtitle
            style="
              text-align: justify;
              font-size: 1.385em;
              line-height: 1.8;
              color: black;
              word-spacing: -2px;
            "
            dir="auto"
            v-text="item.description"
          >
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col style="height: 200px" cols="12" sm="12" md="10">
        <Adsense
          data-ad-client="ca-pub-2248195857071378"
          data-ad-slot="7130091720"
        >
        </Adsense>
      </v-col>
    </v-row>
    <NewsRecommande />
  </v-container>
</template>

<script>
import "firebase/compat/firestore";
import db from "../utils/firebaseInit";
import NodesName from "../utils/nodesName";
import MercanewsLogo from "../assets/Mercanews.png";
import FunctionUtils from "../utils/functions";
import NodesFieldName from "../utils/nodesFieldName";
import Footer from "../components/Footer.vue";
import NewsRecommande from "../components/NewsRecommande.vue";

export default {
  components: { NewsRecommande, Footer },
  data() {
    return {
      detail: [],
      MercanewsLogo: MercanewsLogo,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Detail News",
          disabled: true,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // Récupérer les news
      db.collection(NodesName.RSS_FEED)
        .where(NodesFieldName.UNIQUEID, "==", to.params.uniqueId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            vm.detail.push({
              title: doc.data().title,
              description: doc.data().description,
              image: FunctionUtils.getStringImageQuality(doc.data().image),
              category: doc.data().category,
              date: doc.data().date,
            });
          });
        });
    });
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.article_date {
  color: #6e7780;
  font-size: 14px;
  line-height: 1;
}
.v-application ul,
.v-application ol {
  padding-left: 0px;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px;
}
</style>
