<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <v-card
          v-for="(item, index) in news"
          elevation="0"
          :key="index"
          @click="redirectToDetailNews(item)"
          class="title"
        >
          <v-row>
            <v-col sm="12" xs="12" md="4">
              <v-img
                :src="item.image"
                v-on:error="item.image = mercanews"
                aspect-ratio="2"
                cover
              ></v-img>
            </v-col>
            <v-col sm="12" xs="12" md="8">
              <v-list-item-title class="text-h6 mb-1">
                <h3>{{ item.category }}</h3>
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ item.sourceName }}
              </v-list-item-subtitle>

              <v-card-text style="text-align: justify; word-spacing: -2px">
                <b>{{ item.description }}</b>
              </v-card-text>
            </v-col>
          </v-row>
          <br />
        </v-card>
      </v-col>
    </v-row>
    <InfiniteLoading @infinite="infiniteHandler">
      <div slot="no-more">
        <Footer />
      </div>
      <div slot="no-results">
        <Footer />
      </div>
    </InfiniteLoading>
  </v-container>
</template>
<script>
import "firebase/compat/firestore";
import InfiniteLoading from "vue-infinite-loading";
import FirebaseDB from "../utils/firebaseDB";
import MercanewsLogo from "../assets/Mercanews.png";
import Footer from "../components/Footer.vue";

export default {
  components: {
    InfiniteLoading,
    Footer,
  },
  data() {
    return {
      news: [],
      lastDocSnapshot: null,
      mercanews: MercanewsLogo,
      totalFluxCount: 0,
    };
  },
  methods: {
    redirectToDetailNews(item) {
      if (item.sourceName == "Mercanews") {
        let routeData = this.$router.resolve({
          name: "DetailNews",
          params: {
            uniqueId: item.uniqueId,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        window.open(item.link, "_blank");
      }
    },
    async fetchFlux() {
      let fluxRef =
        FirebaseDB.getArticleFromDatabaseForInfiniteScrollReference();

      if (this.lastDocSnapshot) {
        fluxRef = fluxRef.startAfter(this.lastDocSnapshot);
      }
      const fluxSnap = await fluxRef.get();
      this.lastDocSnapshot = fluxSnap.docs[fluxSnap.docs.length - 1];
      const result =
        await FirebaseDB.getArticleFromDatabaseForInfiniteScrollNewsRecommande(
          fluxSnap,
          new Map(JSON.parse(localStorage.sourceLogo))
        );

      this.news.push(...result);
      return result.length;
    },
    async infiniteHandler($state) {
      const newFluxCount = await this.fetchFlux();
      this.totalFluxCount = this.totalFluxCount + newFluxCount;

      if (newFluxCount > 0 && this.totalFluxCount < 19) {
        return $state.loaded(); // More news to come
      }

      return $state.complete(); // Done with the news
    },
    async setNumberOfViews(flux) {
      await FirebaseDB.setNumberOfViews(flux.uniqueId);
      window.open(flux.link, "_blank");
    },
  },
};
</script>
<style scoped>
.title:hover {
  color: rgb(94, 13, 224);
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px;
}
.v-image {
  border-radius: 10px;
}
</style>
